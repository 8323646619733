<template>
  <div>
    <div class="business-state">
      <div class="business-state-icon" />
      <van-cell>
        <template v-if="progessName" #title>
          <span class="custom-title">目前状态：</span>
          <span :class="progessStatus === 'nh_02_00_st' ? 'custom-now-name-red' : (progessStatus === 'nh_00_01_st' ? 'custom-now-name-red' : ( progessStatus === 'nh_01_00_st' ? 'custom-now-name-green' :  ( progessStatus === 'nh_03_00_st' ? 'custom-now-name-gray' : 'custom-now-name'))) ">{{ progessName }}</span>
        </template>
        <template v-if="progessStatus === 'nh_00_01_st'" #label>
          <span class="custom-label">{{ processingDays ? `剩余${processingDays}个工作日` : '' }}</span>
        </template>
        <template v-else #label>
          <span class="custom-label">{{ processingDays ? `预计${processingDays}个工作日`: '' }}</span>
        </template>
      </van-cell>
      <van-cell v-if="progessStatusName" is-link center @click="BusinessStep">
        <template #title>
          <span class="custom-title">最新操作：</span>
          <span class="custom-new-name">{{ progessStatusName }}</span>
        </template>
        <template #label>
          <span class="custom-label">{{ updatedTime }}</span>
        </template>
      </van-cell>
    </div>
    <!-- nh_00_01_st 拒绝 -->
    <div v-if="progessRemark && progessStatus === 'nh_02_00_st'" class="business-describe">
      <p>尊敬的客户，您提交的以下信息有误，已被拒绝!</p>
      <p>{{ progessRemark }}</p>
    </div>
    <!-- nh_00_01_st 待补缴资料 -->
    <div v-else-if="progessRemark && progessStatus === 'nh_00_01_st'" class="business-describe">
      <p>尊敬的客户，您提交的以下信息有误，请重新提交!</p>
      <p>{{ progessRemark }}</p>
    </div>
  </div>
</template>

<script>
import { formatTime } from '@/utils/tools';
export default {
  name: 'BusinessStatus',
  props: {
    statusInfor: {
      type: Object,
      default: () => {
        return {};
      }
    },
    busHandlingId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      status: null,
      progessName: null,
      progessStatus: null,
      progessStatusCode: null,
      progessStatusName: null,
      updatedTime: null,
      progessRemark: null,
      processingDays: null
    };
  },
  watch: {
    statusInfor: {
      handler() {
        if (this.statusInfor) {
          this.conversionNews(this.statusInfor);
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    /* 解析业务办理信息数据 */
    conversionNews(data) {
      this.stateBoolean = data.stateBoolean;
      this.status = data.status !== undefined ? data.status : null;
      this.progessName = data.progessName !== undefined ? data.progessName : null;
      this.progessStatus = data.progessStatus !== undefined ? data.progessStatus : null;
      this.processingDays = data.processingDays !== undefined ? data.processingDays : null;
      this.progessStatusCode = data.progessStatusCode !== undefined ? data.progessStatusCode : null;
      this.progessStatusName = data.progessStatusName !== undefined ? data.progessStatusName : null;
      this.updatedTime =
        data.progessCreateTime !== undefined ? formatTime(data.progessCreateTime) : null;
      this.progessRemark = data.progessRemark !== undefined ? data.progessRemark : null;
    },
    /* 步骤信息跳转 */
    BusinessStep() {
      if (this.busHandlingId) {
        this.$router.push({ path: '/Business/BusinessStep' });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.business-state {
  margin: 16px;
  overflow: hidden;
  background: #ffffff;
  border-radius: 8px;
  position: relative;

  .business-state-icon {
    width: 4px;
    height: 20px;
    background: #4380e4;
    position: absolute;
    top: 10px;
    z-index: 99;
  }

  .custom-title {
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
  }

  .custom-label {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
  }

  .custom-now-name {
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: #4380e4;
  }

  .custom-now-name-red {
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: #ff5858;
  }

  .custom-now-name-gray {
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: #333;
  }

  .custom-now-name-green {
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: #25b1bf;
  }

  .custom-new-name {
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
  }

  /* 审核中 */
  .underReview {
    color: #4380e4;
  }

  /* 补交资料 */
  .makeUp {
    color: #f64357;
  }

  /* 处理中 */
  /* 办结 */
  .Finish {
    color: #25b1bf;
  }

  /* 拒绝 */
  .refuse {
    color: #f64357;
  }

  /* 取消 */
  .cancel {
    color: #a2b0c6;
  }
}
.business-describe {
  margin: 16px;
  overflow: hidden;
  background: #ffecde;
  border-radius: 8px;
  padding: 10px 16px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #fc882c;
  line-height: 20px;

  p {
    margin: 0;
  }
}
</style>
