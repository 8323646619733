/*
 * @Author: yangliao
 * @Date: 2020-11-17 14:09:36
 * @LastEditTime: 2021-04-29 11:02:38
 * @LastEditors: yangliao
 * @Description: 业务相关接口
 * @FilePath: /netHallOfficialAccounts/src/api/business.js
 */
import request from '@/utils/service';

/* 业务办理查询 */
export function selectBusHandlingPage(params) {
  return request({
    url: `/busHandling/selectBusHandlingPage`,
    method: 'post',
    data: params
  });
}
/* 业务进度查询 */
export function selectBusHandlingProgess(id) {
  return request({
    url: `/busHandling/selectBusHandlingProgess/${id}`,
    method: 'get'
  });
}
/* 获取绑定表卡 */
export function selectAccount() {
  return request({
    url: `/busHandling/selectAccount`,
    method: 'get'
  });
}
/* 根据业务code查询用户须知 */
export function getCustReadingByCode(params) {
  return request({
    url: `/custReading/getCustReadingByCode`,
    method: 'post',
    data: params
  });
}
/* 认证信息 */
export function selectWaterUserRel() {
  return request({
    url: `/busHandling/selectWaterUserRel`,
    method: 'get'
  });
}
/* 业务新增 */
export function addBusHandling(params) {
  return request({
    url: `/busHandling/addBusHandling`,
    method: 'post',
    data: params
  });
}
/* 通过ID查询业务信息 */
export function selectBusHandlingById(id) {
  return request({
    url: `/busHandling/selectBusHandlingById/${id}`,
    method: 'get'
  });
}
/* 业务修改 */
export function updateBusHandling(params) {
  return request({
    url: `/busHandling/updateBusHandling`,
    method: 'post',
    data: params
  });
}
/* 业务取消 */
export function cancelReport(id) {
  return request({
    url: `/busHandling/cancelReport/${id}`,
    method: 'get'
  });
}
/* 业务验证 */
export function verification(params) {
  return request({
    url: `/busHandling/verification`,
    method: 'post',
    data: params
  });
}
/* 业务办理用信息 */
export function selectHandUser(params) {
  return request({
    url: `/busHandling/selectHandUser?waterUserNo=${params}`,
    method: 'get'
  });
}
// /busHandling/isMaster

/* 户主验证 */
export function isMaster(params) {
  return request({
    url: `/busHandling/isMaster/${params}`,
    method: 'get'
  });
}

/* 获取营业厅信息 */
export function selectListPageForNetHall() {
  return request({
    url: `/WxBranchNetwork/list`,
    method: 'get'
  });
}

/* 自助抄表-获取用户关联的水表信息 */
export function queryUserAndMreadInfo(params) {
  return request({
    url: `/selfMeterRead/queryUserAndMreadInfo`,
    method: 'post',
    data: params
  });
}
/**
 * 自主抄表-抄表
 */
export function addSelfMeterRead(params) {
  return request({
    url: `/selfMeterRead/addSelfMeterRead`,
    method: 'post',
    data: params
  });
}
/* 自助抄表-根据水表编号查询抄表记录 */
export function findReadRecord(data) {
  return request({
    url: `/selfMeterRead/selectSelfMeterReadInfoByMeterCode`,
    method: 'post',
    data
  });
}
